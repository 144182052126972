import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const GooglePayV2 = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill={colors["neutral-50"]}
    {...rest}
  >
    <rect width="36" height="24" rx="2" fill="#F7F7F7" />
    <path
      d="M17.1 14.9237H16.1088V7.30494H18.7366C19.4025 7.30494 19.9704 7.52423 20.435 7.96279C20.91 8.40135 21.1475 8.9368 21.1475 9.56915C21.1475 10.2168 20.91 10.7522 20.435 11.1857C19.9756 11.6192 19.4077 11.8333 18.7366 11.8333H17.1V14.9237ZM17.1 8.24326V10.9001H18.7572C19.1496 10.9001 19.48 10.7675 19.7381 10.5075C20.0014 10.2474 20.1356 9.93121 20.1356 9.57425C20.1356 9.22238 20.0014 8.9113 19.7381 8.65123C19.48 8.38095 19.1547 8.24836 18.7572 8.24836H17.1V8.24326Z"
      fill="#3C4043"
    />
    <path
      d="M23.7399 9.53846C24.473 9.53846 25.0512 9.73225 25.4745 10.1198C25.8979 10.5074 26.1095 11.0377 26.1095 11.7109V14.9236H25.1648V14.1995H25.1235C24.7156 14.7961 24.1684 15.0919 23.4869 15.0919C22.9036 15.0919 22.4183 14.9236 22.0259 14.5819C21.6336 14.2403 21.4374 13.817 21.4374 13.307C21.4374 12.7665 21.6439 12.3381 22.0569 12.0219C22.4699 11.7007 23.0223 11.5426 23.7089 11.5426C24.2975 11.5426 24.7827 11.6497 25.1596 11.8639V11.6395C25.1596 11.2978 25.0254 11.0122 24.7518 10.7726C24.4782 10.5329 24.1581 10.4156 23.7915 10.4156C23.2391 10.4156 22.8003 10.6451 22.4802 11.1091L21.6078 10.5686C22.0879 9.88013 22.8003 9.53846 23.7399 9.53846ZM22.4596 13.3223C22.4596 13.5773 22.568 13.7915 22.79 13.9598C23.0068 14.1281 23.265 14.2148 23.5592 14.2148C23.9774 14.2148 24.3491 14.0618 24.6743 13.7558C24.9996 13.4498 25.1648 13.0929 25.1648 12.6798C24.855 12.4401 24.4265 12.3177 23.8741 12.3177C23.4715 12.3177 23.1359 12.4146 22.8674 12.6033C22.5938 12.8022 22.4596 13.0419 22.4596 13.3223Z"
      fill="#3C4043"
    />
    <path
      d="M31.5 9.70729L28.196 17.2138H27.1738L28.4025 14.5876L26.2239 9.70729H27.3028L28.8723 13.4504H28.8929L30.421 9.70729H31.5Z"
      fill="#3C4043"
    />
    <path
      d="M13.1597 11.2181C13.1597 10.8988 13.1308 10.5934 13.0771 10.2996H8.92227V11.9825L11.3151 11.983C11.2181 12.5429 10.9057 13.0202 10.4272 13.3384V14.4303H11.8515C12.6832 13.6699 13.1597 12.546 13.1597 11.2181Z"
      fill="#4285F4"
    />
    <path
      d="M10.4272 13.3384C10.0307 13.6026 9.51987 13.7563 8.92257 13.7563C7.76874 13.7563 6.78991 12.9883 6.43938 11.9531H4.97012V13.0791C5.69804 14.5059 7.19414 15.485 8.92257 15.485C10.1172 15.485 11.1208 15.0969 11.8513 14.4289L10.4272 13.3384Z"
      fill="#34A853"
    />
    <path
      d="M6.30121 11.1181C6.30121 10.8274 6.35026 10.5464 6.43957 10.2823V9.15629H4.97031C4.66933 9.74631 4.5 10.4123 4.5 11.1181C4.5 11.8239 4.66965 12.489 4.97012 13.0791L6.43938 11.9531C6.35007 11.6889 6.30121 11.4088 6.30121 11.1181Z"
      fill="#FABB05"
    />
    <path
      d="M8.92248 8.47875C9.57451 8.47875 10.1584 8.70058 10.6194 9.13404L11.8817 7.88822C11.115 7.18295 10.1155 6.75 8.92248 6.75C7.19457 6.75 5.69823 7.72943 4.97031 9.15629L6.43957 10.2823C6.79011 9.24706 7.76864 8.47875 8.92248 8.47875Z"
      fill="#E94235"
    />
  </svg>
);
