import { ApolloProvider as ApolloClientProvider } from "@apollo/client";
import { PropsWithChildren } from "react";

import { GRAPHQL_API_URL } from "@/env";

import { createApolloClient } from "./createApolloClient";

const client = createApolloClient(GRAPHQL_API_URL!);

export function ApolloProvider({ children }: PropsWithChildren) {
  return (
    <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
  );
}
