import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const CardMastercard = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill={colors["neutral-50"]}
    {...rest}
  >
    <rect width="36" height="24" rx="2" fill="#F7F7F7" />
    <path
      d="M18 5.63574C16.1677 7.14893 15 9.4381 15 12C15 14.5619 16.1677 16.8511 18 18.3643C16.5736 19.5423 14.7444 20.25 12.75 20.25C8.19365 20.25 4.5 16.5563 4.5 12C4.5 7.44365 8.19365 3.75 12.75 3.75C14.7444 3.75 16.5736 4.45773 18 5.63574Z"
      fill="#EB001B"
    />
    <path
      d="M18 18.3643C19.8323 16.8511 21 14.5619 21 12C21 9.43806 19.8323 7.14893 18 5.63574C16.1677 7.14893 15 9.4381 15 12C15 14.5619 16.1677 16.8511 18 18.3643Z"
      fill="#FF5F00"
    />
    <path
      d="M18 18.3643C19.8323 16.8511 21 14.5619 21 12C21 9.43806 19.8323 7.14893 18 5.63574C19.4264 4.45773 21.2556 3.75 23.25 3.75C27.8063 3.75 31.5 7.44365 31.5 12C31.5 16.5563 27.8063 20.25 23.25 20.25C21.2556 20.25 19.4264 19.5423 18 18.3643Z"
      fill="#F79E1B"
    />
  </svg>
);
