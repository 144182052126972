import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const CardAmex = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill={colors["neutral-50"]}
    {...rest}
  >
    <rect width="36" height="24" rx="2" fill="#F7F7F7" />
    <path
      d="M3 3.75C3 2.92157 3.67157 2.25 4.5 2.25H31.5C32.3284 2.25 33 2.92157 33 3.75V20.25C33 21.0784 32.3284 21.75 31.5 21.75H4.5C3.67157 21.75 3 21.0784 3 20.25V3.75Z"
      fill="#0071CE"
    />
    <path
      d="M33 4.87482H29.823L29.1585 6.78152L28.4652 4.87482H24.3045V7.41169L23.169 4.87482H19.8385L16.3935 12.7383H19.1724V19.639H27.7087L29.0628 18.1538L30.4169 19.639H33V4.87482Z"
      fill="white"
    />
    <path
      d="M30.8308 18.7015H32.941L30.1464 15.6788L32.941 12.7131H30.8308L29.1198 14.5951L27.3518 12.7131H25.1846L28.0362 15.6788L25.1846 18.7015H27.2948L29.0628 16.7624L30.8308 18.7015Z"
      fill="#0071CE"
    />
    <path
      d="M21.7638 17.2757V16.3632H25.1288V14.9944H21.7638V14.0818H25.1858V12.7131H20.1099V18.7015H25.1858V17.2757H21.7638Z"
      fill="#0071CE"
    />
    <path
      d="M31.4016 11.8008H32.9985V5.81236H30.4891L29.1773 9.57653L27.8085 5.81236H25.242V11.8008H26.896V7.63741L28.4359 11.8008H29.8617L31.4016 7.63741V11.8008Z"
      fill="#0071CE"
    />
    <path
      d="M22.5615 5.81236H20.4513L17.8278 11.8008H19.6528L20.1661 10.6031H22.9037L23.417 11.8008H25.242L22.5615 5.81236ZM20.6794 9.29137L21.4779 7.35225L22.2763 9.29137H20.6794Z"
      fill="#0071CE"
    />
    <path
      d="M31.4601 15.5076L33 17.1615V13.8536L31.4601 15.5076Z"
      fill="#0071CE"
    />
  </svg>
);
