import {
  CollectCheckoutDetails,
  CollectInvoiceStatus,
  CollectValidPaymentMethod,
  Money,
} from "@/gql";

export const toDollars = (money?: Money | null) =>
  (money?.amountInCents ?? 0) / 100;

export const toPercentage = (number: number) => (number * 100).toFixed(2);

export const numberToDollars = (number?: number | null) => (number ?? 0) / 100;

export const toCurrency = (value: number, smart = true) => {
  let decimal = 2;
  if (value % 1 === 0 && smart) {
    decimal = 0;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return formatter.format(value);
};

export const isInvoicePaid = (details: CollectCheckoutDetails) =>
  [
    CollectInvoiceStatus.StatusPaid,
    CollectInvoiceStatus.StatusPaymentPending,
  ].includes(details.status);

export const isSavedPaymentMethod = (
  paymentMethod?: CollectValidPaymentMethod,
) =>
  paymentMethod &&
  [CollectValidPaymentMethod.Ach, CollectValidPaymentMethod.Card].includes(
    paymentMethod,
  );

export const isExpiredCard = (expMonth: number, expYear: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  return (
    expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth)
  );
};

export const hasCreditCardFee = (paymentMethod?: CollectValidPaymentMethod) =>
  !!paymentMethod &&
  [
    CollectValidPaymentMethod.Card,
    CollectValidPaymentMethod.ApplePay,
    CollectValidPaymentMethod.GooglePay,
  ].includes(paymentMethod);

export const getBalanceRemaining = (
  paymentMethod?: CollectValidPaymentMethod,
  balanceDue?: Money,
  potentialBalanceDueWithCreditCardFee?: Money,
) =>
  hasCreditCardFee(paymentMethod)
    ? potentialBalanceDueWithCreditCardFee
    : balanceDue;

export const isBalanceCoveredByFunds = (
  paymentMethod?: CollectValidPaymentMethod,
  accountFundsToUse?: Money,
  potentialTotalWithCreditCardFee?: Money,
  total?: Money,
) =>
  !!accountFundsToUse &&
  accountFundsToUse.amountInCents >=
    (getBalanceRemaining(paymentMethod, total, potentialTotalWithCreditCardFee)
      ?.amountInCents ?? 0);
