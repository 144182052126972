import React, { ReactElement } from "react";

import { colors } from "@/utils/colors";

import { IconProps } from "../../types/svgTypes";

export const FanaticsIcon = ({
  color = colors["neutral-1000"],
  ...rest
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 28"
    {...rest}
  >
    <path
      d="M6.43016 3.03818C4.26784 2.85211 1.39331 2.25539 0.758086 2.10461C0.421226 2.06611 0.235151 2.40297 0.385936 2.62754C1.61788 4.49147 5.23671 10.048 5.19822 10.048C5.272 10.1218 5.3105 10.1218 5.38429 10.1603C12.8818 11.7996 21.4252 4.97911 28.7752 8.66848C28.849 8.74227 28.9998 8.66848 29.0735 8.5947L32.2817 3.6349C32.3555 3.52261 32.3555 3.37504 32.2432 3.29804C23.9982 -3.15355 17.8417 3.67019 6.42695 3.03818H6.43016Z"
      fill={color}
    />
    <path
      d="M26.9497 11.575C21.3546 9.63732 16.1317 13.3652 8.14649 13.5513C7.92191 13.5513 7.73584 13.7758 7.88662 14.0742L16.4685 27.3848C16.5808 27.6094 16.8792 27.4586 16.8407 27.2725L14.9767 18.8479C14.9382 18.7741 15.0152 18.7003 15.0505 18.6618C15.798 18.4373 20.6841 16.7979 22.9972 17.8791C23.071 17.9176 23.1095 17.8791 23.1833 17.8053L27.0267 11.8028C27.1004 11.6905 27.0267 11.6168 26.9529 11.5783L26.9497 11.575Z"
      fill={color}
    />
  </svg>
);
