import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";

export const OctagonAlert = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3787 0H30.6213L42 11.3787V30.6213L30.6213 42H11.3787L0 30.6213V11.3787L11.3787 0ZM22.649 24L22.9495 16.5L23.25 9H18.75L19.365 24H22.649ZM21 33C20.3827 33 19.8519 32.7795 19.4077 32.3385C18.9692 31.8975 18.75 31.3636 18.75 30.7369C18.75 30.1219 18.9692 29.5967 19.4077 29.1615C19.8519 28.7205 20.3827 28.5 21 28.5C21.5942 28.5 22.1163 28.7205 22.5663 29.1615C23.0221 29.5967 23.25 30.1219 23.25 30.7369C23.25 31.1547 23.1433 31.5348 22.9298 31.8772C22.7221 32.2195 22.4481 32.4923 22.1077 32.6954C21.7731 32.8985 21.4038 33 21 33Z"
      fill="#90180E"
    />
  </svg>
);
