import { gql } from "./artifacts";

export const collectCurrentUser = gql(`
  query webCurrentUserV2CheckoutQuery {
    collectCurrentUserV2 {
      __typename
      firstName
      lastName
      tenantId
      frozen
      email {
        __typename
        address
      }
      accountFunds {
        __typename
        total {
          __typename
          ...SharedMoney
        }
        pendingUnderwriting {
          __typename
          ...SharedMoney
        }
        usableFunds {
          __typename
          ...SharedMoney
        }
      }
      collectDefaultAddress {
        __typename
        ...CollectSharedAddress
      }
      collectVaultSubmissionAddress {
        __typename
        ...CollectSharedAddress
      }
      collectInvoices {
        edges {
          node {
            id
            integerId
          }
        }
      }
    }
  }

  fragment CollectSharedAddress on CollectAddress {
    __typename
    name
    addressLine1
    addressLine2
    city
    state
    zip
    country
  }

  fragment SharedMoney on Money {
    __typename
    amountInCents
    currency
  }
`);
