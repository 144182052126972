export const colors = {
  "neutral-0": "#FFFFFF",
  "neutral-50": "#F7F7F7",
  "neutral-100": "#E8E8E8",
  "neutral-200": "#CBCCCC",
  "neutral-300": "#A9ABAC",
  "neutral-400": "#8F9192",
  "neutral-500": "#7A7B7D",
  "neutral-600": "#5B5C5E",
  "neutral-700": "#48494C",
  "neutral-800": "#38393C",
  "neutral-900": "#2C2D30",
  "neutral-950": "#222326",
  "neutral-975": "#101114",
  "neutral-1000": "#000000",
  "red-50": "#FEF6F6",
  "red-100": "#FFE2DF",
  "red-200": "#FFBBB5",
  "red-300": "#FF897F",
  "red-400": "#F85D51",
  "red-500": "#E53C2E",
  "red-600": "#B22115",
  "red-700": "#90180E",
  "red-800": "#72120A",
  "red-900": "#4F0C07",
  "red-950": "#2A0704",
  "orange-50": "#FFF7ED",
  "orange-100": "#FFE4C4",
  "orange-200": "#FFC076",
  "orange-300": "#FAA542",
  "orange-400": "#DA7805",
  "orange-500": "#B75A01",
  "orange-600": "#874401",
  "orange-700": "#793601",
  "orange-800": "#5F2A01",
  "orange-900": "#3F1D00",
  "orange-950": "#200F00",
  "green-50": "#F2FBF3",
  "green-100": "#CDF1D3",
  "green-200": "#8DDF98",
  "green-300": "#48CB57",
  "green-400": "#30A63F",
  "green-500": "#0F851E",
  "green-600": "#0E721B",
  "green-700": "#0A5514",
  "green-800": "#08420F",
  "green-900": "#052D0A",
  "green-950": "#031805",
  "blue-50": "#F5F9FE",
  "blue-100": "#DDEAFD",
  "blue-200": "#B1CEF9",
  "blue-300": "#7CACF5",
  "blue-400": "#5091F1",
  "blue-500": "#2173EE",
  "blue-600": "#115CCB",
  "blue-700": "#0C4599",
  "blue-800": "#0A3678",
  "blue-900": "#062451",
  "blue-950": "#03132A",
  "glo-50": "#EDFFD6",
  "glo-100": "#D9FEA9",
  "glo-200": "#B7FF59",
  "glo-300": "#7AD700",
  "glo-400": "#5CA200",
  "glo-500": "#4D8A00",
  "glo-600": "#3A6800",
  "glo-700": "#2E5200",
  "glo-800": "#244000",
  "glo-900": "#182B00",
  "glo-950": "#0C1600",
  "pink-500": "#F52757",
};
