import { t } from "i18next";

import {
  CollectAddress,
  CollectAlternatingShippingAddress,
  CollectCheckoutV2BurbankShipping,
  CollectCheckoutV2BurbankShippingType,
  CollectCheckoutV2Details,
  CollectCheckoutV2DetailsBurbankSportscardsShippingInput,
  CollectCheckoutV2DetailsFanaticsCollectShippingInput,
  CollectCheckoutV2FanaticsShipping,
  CollectCheckoutV2FanaticsShippingType,
  CollectCheckoutV2Seller,
  CollectCheckoutV2ShippingMethod,
  CollectListingType,
  CollectShippingMethod,
  Maybe,
  CollectCheckoutV2AddressInput,
  CollectCheckoutV2DetailsSectionTotals,
  CollectCheckoutV2LineItem,
} from "@/gql";
import { IMulticartDetailsOptions } from "@/hooks/useCheckoutV2Details";

export const getListingTypeText = (listingType: CollectListingType): string => {
  switch (listingType) {
    case CollectListingType.FixedPrice:
      return t("checkout_v2.multicart.seller_section.listing_text.buy_now");
    case CollectListingType.Weekly:
      return t("checkout_v2.multicart.seller_section.listing_text.weekly");
    case CollectListingType.Premier:
      return t("checkout_v2.multicart.seller_section.listing_text.premier");
  }
};

export const getShippingBySeller = (
  seller: CollectCheckoutV2Seller,
  details: CollectCheckoutV2Details | undefined,
): Maybe<
  | CollectCheckoutV2FanaticsShipping
  | CollectCheckoutV2BurbankShipping
  | undefined
> => {
  switch (seller) {
    case CollectCheckoutV2Seller.FanaticsCollect:
      return details?.fanaticsCollectSection?.shipping;
    case CollectCheckoutV2Seller.BurbankSportscards:
      return details?.burbankSportscardsSection?.shipping;
    case CollectCheckoutV2Seller.FanaticsAuthentic:
      return details?.fanaticsAuthenticSection?.shipping;
  }
};
export const getTotalsBySeller = (
  seller: CollectCheckoutV2Seller,
  details: CollectCheckoutV2Details | undefined,
): Maybe<CollectCheckoutV2DetailsSectionTotals | undefined> => {
  switch (seller) {
    case CollectCheckoutV2Seller.FanaticsCollect:
      return details?.fanaticsCollectSection?.totals;
    case CollectCheckoutV2Seller.BurbankSportscards:
      return details?.burbankSportscardsSection?.totals;
    case CollectCheckoutV2Seller.FanaticsAuthentic:
      return details?.fanaticsAuthenticSection?.totals;
  }
};

export const getShippingDetailsBySeller = (
  seller: CollectCheckoutV2Seller,
  details: IMulticartDetailsOptions | undefined,
):
  | CollectCheckoutV2DetailsFanaticsCollectShippingInput
  | CollectCheckoutV2DetailsBurbankSportscardsShippingInput
  | undefined => {
  switch (seller) {
    case CollectCheckoutV2Seller.FanaticsCollect:
      return details?.fanaticsCollectSection?.shipping;
    case CollectCheckoutV2Seller.BurbankSportscards:
      return details?.burbankSportscardsSection?.shipping;
    case CollectCheckoutV2Seller.FanaticsAuthentic:
      return details?.fanaticsAuthenticSection?.shipping;
  }
};
export const getAlternateAddressBySeller = (
  seller: CollectCheckoutV2Seller,
  details: IMulticartDetailsOptions | undefined,
): CollectCheckoutV2AddressInput | undefined => {
  switch (seller) {
    case CollectCheckoutV2Seller.FanaticsCollect:
      return details?.fanaticsCollectSection?.alternateAddress;
    case CollectCheckoutV2Seller.BurbankSportscards:
      return details?.burbankSportscardsSection?.alternateAddress;
    case CollectCheckoutV2Seller.FanaticsAuthentic:
      return details?.fanaticsAuthenticSection?.alternateAddress;
  }
};

const isDomesticAddress = (
  shippingType?:
    | CollectCheckoutV2FanaticsShippingType
    | CollectCheckoutV2BurbankShippingType
    | null,
  address?: CollectAddress | CollectAlternatingShippingAddress | null,
) => {
  if (!address) {
    return true;
  }
  return (
    ((shippingType === CollectCheckoutV2FanaticsShippingType.Default ||
      shippingType === CollectCheckoutV2BurbankShippingType.Default) &&
      address?.country === "US") ||
    ((shippingType === CollectCheckoutV2FanaticsShippingType.New ||
      shippingType === CollectCheckoutV2BurbankShippingType.New) &&
      address?.country === "1")
  );
};

export const getValidShippingMethods = (
  shippingType?:
    | CollectCheckoutV2FanaticsShippingType
    | CollectCheckoutV2BurbankShippingType
    | null
    | undefined,
  availableShippingMethods?:
    | Maybe<Maybe<CollectCheckoutV2ShippingMethod>[]>
    | undefined,
  address?: CollectAddress | CollectAlternatingShippingAddress | null,
) => {
  const isDomestic = isDomesticAddress(shippingType, address);

  return availableShippingMethods?.length
    ? (availableShippingMethods.filter(
        (method) => method?.usDomestic === isDomestic,
      ) as CollectShippingMethod[])
    : [];
};

export const getAvailableShippingMethods = (
  detailsShipping: Maybe<
    | CollectCheckoutV2FanaticsShipping
    | CollectCheckoutV2BurbankShipping
    | undefined
  >,
): Maybe<Maybe<CollectCheckoutV2ShippingMethod>[]> | undefined => {
  if (detailsShipping && "availableMethods" in detailsShipping) {
    return (detailsShipping as CollectCheckoutV2BurbankShipping)
      .availableMethods;
  }
};

export const getDefaultShippingMethodId = (
  address: CollectCheckoutV2AddressInput | CollectAddress | null | undefined,
  availableShippingMethods:
    | Maybe<Maybe<CollectCheckoutV2ShippingMethod>[]>
    | undefined,
) => {
  const addressCountry = address?.country;
  const isDomestic = addressCountry === "US" || addressCountry === "1";

  return availableShippingMethods?.find(
    (method) => method?.usDomestic === isDomestic,
  )?.id;
};

function getFieldName<T extends keyof CollectCheckoutV2Details>(
  fieldName: T,
): T {
  return fieldName;
}

export const getSectionField = (seller: CollectCheckoutV2Seller) => {
  switch (seller) {
    case CollectCheckoutV2Seller.FanaticsCollect:
      return getFieldName("fanaticsCollectSection");
    case CollectCheckoutV2Seller.BurbankSportscards:
      return getFieldName("burbankSportscardsSection");
    case CollectCheckoutV2Seller.FanaticsAuthentic:
      return getFieldName("fanaticsAuthenticSection");
  }
};

export const alternateAddressAdapter = (
  address: CollectAlternatingShippingAddress,
): CollectCheckoutV2AddressInput => {
  return {
    name: address.name ?? "",
    city: address.city ?? "",
    country: address.country ?? "",
    phone: "",
    state: address.state ?? "",
    street1: address.addressLine1 ?? "",
    street2: address.addressLine2,
    zip: address.zip ?? "",
  };
};

export const convertDefaultAddress = (
  defaultAddress: CollectCheckoutV2AddressInput,
) => {
  return {
    name: defaultAddress.name || "",
    street1: defaultAddress.street1 || "",
    street2: defaultAddress.street2 || "",
    city: defaultAddress.city || "",
    state: defaultAddress.state || "",
    countryId: parseInt(defaultAddress.country) || 0,
    zip: defaultAddress.zip || "",
  };
};

export const hasAvailableItems = (
  lineItems: Maybe<Maybe<CollectCheckoutV2LineItem>[]> | undefined,
) => {
  return lineItems?.some((item) => item?.available);
};
