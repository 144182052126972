import { gql } from "./artifacts";

//   "variables": {
//     "input": {
//       "listing": {
//         "listingId": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//         "listingType": "FIXED_PRICE",
//         "paymentMethod": [
//           "CARD"
//         ],
//         "shippingType": "VAULT"
//       }
//     }
//   }

export const collectCheckoutPaymentLock = gql(`
  mutation webCheckoutPaymentLockMutation($input: CollectCheckoutPaymentLockInput!) {
    collectCheckoutPaymentLock(input: $input) {
      __typename
      successful
      messages {
        __typename
        ...ErrorMessage
      }
      result {
        __typename
        clientSecret
        invoiceId
        paymentIntentId
      }
    }
  }
`);
