import { TFunction } from "i18next";
import { Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import {
  NavigateOptions,
  Params,
  useLocation,
  useMatches,
} from "react-router-dom";

import { cn } from "@/utils";

import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPrimitive,
  BreadcrumbSeparator,
} from "./primitive";

type Key = Parameters<TFunction>[0];
type Option = Parameters<TFunction>[1];

type BreadcrumbEntry =
  | {
      href?: string;
      label: Key | (Key | Option)[];
    }[]
  | null;

export type Handle = {
  breadcrumb:
    | BreadcrumbEntry
    | ((params: Params, state: NavigateOptions["state"]) => BreadcrumbEntry);
};
// Define the type for each match object returned by `useMatches`
type MatchWithBreadcrumb = {
  id: string;
  pathname: string;
  params: Record<string, string | undefined>;
  handle?: Handle;
};
export function Breadcrumb() {
  const { t } = useTranslation();
  const matches = useMatches() as MatchWithBreadcrumb[];
  const { pathname, state } = useLocation();
  const pageBreadcrumb = matches.find((match) => match.pathname === pathname);
  if (
    !pageBreadcrumb ||
    !pageBreadcrumb.handle ||
    !pageBreadcrumb?.handle?.breadcrumb ||
    pageBreadcrumb?.handle?.breadcrumb === null
  ) {
    return null;
  }
  const crumbs =
    typeof pageBreadcrumb.handle.breadcrumb === "function"
      ? pageBreadcrumb.handle.breadcrumb(pageBreadcrumb.params, state)
      : pageBreadcrumb.handle.breadcrumb;
  if (crumbs === null) {
    return null;
  }
  return (
    <div className="w-full bg-white">
      <BreadcrumbPrimitive className="w-full bg-white px-8 py-6 lg:container md:px-4">
        <BreadcrumbList>
          {crumbs.map((crumb, i) => {
            const text =
              typeof crumb.label === "string"
                ? // @ts-expect-error There is a limitation as the translation function infers the keys from our translation so we ignore them here
                  (t(crumb.label) as string)
                : // @ts-expect-error There is a limitation as the translation function infers the keys from our translation so we ignore them here
                  (t(crumb.label[0], crumb.label[1]) as string);
            return (
              <Fragment key={i}>
                <BreadcrumbItem className="capitalize">
                  <BreadcrumbLink
                    href={crumb.href}
                    className={cn("text-body-sm-bold", {
                      "font-normal text-neutral-950": !crumb.href,
                      "hover:cursor-pointer hover:underline": crumb.href,
                    })}
                  >
                    {text}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="font-normal text-neutral-950 last:hidden">
                  /
                </BreadcrumbSeparator>
              </Fragment>
            );
          })}
        </BreadcrumbList>
      </BreadcrumbPrimitive>
    </div>
  );
}
