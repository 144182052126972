import { Icon, IconProps } from "@chakra-ui/react";
import { EColor } from "@fanatics-live/common-components";

export const CheckmarkRoundedIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M16 29.3327C23.3638 29.3327 29.3334 23.3631 29.3334 15.9993C29.3334 8.63555 23.3638 2.66602 16 2.66602C8.63622 2.66602 2.66669 8.63555 2.66669 15.9993C2.66669 23.3631 8.63622 29.3327 16 29.3327Z"
      fill={EColor.Lime}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33331 16.666L13.3333 20.666L22.6666 11.3327"
      stroke={EColor.Black}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
