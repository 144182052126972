import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const CardVisa = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill={colors["neutral-50"]}
    {...rest}
  >
    <rect width="36" height="24" rx="2" fill="#F7F7F7" />
    <path
      d="M14.6394 7.65908L11.0139 16.3639H8.64849L6.86436 9.41704C6.75604 8.98916 6.66186 8.83241 6.33244 8.65214C5.79461 8.35852 4.90644 8.08304 4.125 7.91206L4.17808 7.65908H7.9856C8.47094 7.65908 8.90723 7.9842 9.01743 8.54662L9.95968 13.5837L12.2884 7.65893L14.6394 7.65908ZM23.9073 13.5218C23.9168 11.2244 20.7504 11.0978 20.7722 10.0715C20.779 9.75914 21.0745 9.42706 21.7214 9.34229C22.042 9.30006 22.9254 9.26784 23.9273 9.73186L24.3204 7.88593C23.782 7.68927 23.0892 7.5 22.2273 7.5C20.0155 7.5 18.459 8.6832 18.4459 10.3775C18.4316 11.6306 19.5569 12.3299 20.4047 12.7463C21.2768 13.1728 21.5695 13.4468 21.5662 13.8282C21.56 14.4121 20.8705 14.6699 20.2263 14.6801C19.1016 14.6975 18.4489 14.3738 17.9287 14.1304L17.5231 16.0374C18.0459 16.2788 19.011 16.4894 20.0115 16.5C22.3623 16.5 23.9001 15.3315 23.9073 13.5218ZM29.7477 16.3639H31.8173L30.0108 7.65908H28.1006C27.6711 7.65908 27.3088 7.91075 27.1484 8.2977L23.7906 16.3639H26.1403L26.6067 15.0637H29.4776L29.7477 16.3639ZM27.251 13.2796L28.4288 10.0113L29.1067 13.2796H27.251ZM17.8365 7.65908L15.9862 16.3639H13.7486L15.5997 7.65908H17.8365Z"
      fill="#172B85"
    />
  </svg>
);
