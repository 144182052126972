import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";

import { ACCESS_TOKEN_COOKIE } from "@/env";

import { tokenLink } from "./tokenLink";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildAuthHeaders = (existingHeaders: any, newAccessToken?: string) => {
  const pwccAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);

  const accessToken: string = newAccessToken || pwccAccessToken || "";

  return {
    headers: {
      ...existingHeaders,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      "X-Platform": "WEB",
    },
  };
};

const authHeadersLink = setContext((_, { headers }) => {
  return buildAuthHeaders(headers);
});

export const createApolloClient = (graphqlApiUrl: string) => {
  const httpLink = new HttpLink({
    uri: graphqlApiUrl,
  });

  const link = from([tokenLink, authHeadersLink, httpLink]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    link,
  });
};
