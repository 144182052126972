import {
  CollectAddress,
  CollectAlternatingShippingAddress,
  CollectCheckoutDetails,
  CollectShippingAddress,
  CollectShippingMethod,
  CollectShippingType,
} from "../../gql";
import { ICheckoutDetailsOptions } from "../../hooks";

export const hasCombinedShippingBanner = (
  optionsVisible: boolean,
  detailsOptions: ICheckoutDetailsOptions,
  details?: CollectCheckoutDetails,
) =>
  !!detailsOptions.listingId && // Buy Now single item checkout
  !details?.availableShippingMethods?.length && // not Burbank
  !optionsVisible &&
  details?.shippingType &&
  !(details.shippingType === CollectShippingType.Vault);

export const hasShipping = (details?: CollectCheckoutDetails) =>
  !!details?.availableShippingTypes?.length &&
  !details.availableShippingTypes.some(
    (shippingType) =>
      shippingType &&
      [CollectShippingType.InPerson, CollectShippingType.TradeShow].includes(
        shippingType,
      ),
  );

export const shippingTypeIsSet = (
  shippingType: CollectShippingType,
  detailsOptions: ICheckoutDetailsOptions,
): boolean => {
  if (shippingType === CollectShippingType.Vault) {
    return shippingType !== detailsOptions.shippingType;
  }
  if (shippingType === CollectShippingType.New) {
    return (
      shippingType !== detailsOptions.shippingType &&
      detailsOptions.alternateShippingAddress !== undefined
    );
  }
  if (shippingType === CollectShippingType.Default) {
    return shippingType !== detailsOptions.shippingType;
  }
  return false;
};

export const isTradeShowInvoice = (details?: CollectCheckoutDetails) =>
  !!details?.availableShippingTypes?.includes(CollectShippingType.TradeShow);

const isDomesticAddress = (
  shippingType?: CollectShippingType,
  collectDefaultAddress?:
    | CollectAddress
    | CollectAlternatingShippingAddress
    | null,
  alternateShippingAddress?: CollectAlternatingShippingAddress,
) =>
  (shippingType === CollectShippingType.Default &&
    collectDefaultAddress?.country === "US") ||
  (shippingType === CollectShippingType.New &&
    alternateShippingAddress?.country === "1");

export const getValidShippingMethods = (
  shippingType?: CollectShippingType,
  availableShippingMethods?: (CollectShippingMethod | null)[] | null,
  collectDefaultAddress?:
    | CollectAddress
    | CollectAlternatingShippingAddress
    | null,
  alternateShippingAddress?: CollectAlternatingShippingAddress,
) => {
  const isDomestic = isDomesticAddress(
    shippingType,
    collectDefaultAddress,
    alternateShippingAddress,
  );

  // const withShippingCostZero = [
  //   {
  //     __typename: "CollectShippingMethod",
  //     cost: {
  //       __typename: "Money",
  //       amountInCents: 0,
  //       currency: "USD",
  //     },
  //     id: "free-shipping",
  //     name: "Free Shipping",
  //     usDomestic: true,
  //   },
  //   ...(availableShippingMethods || []),
  // ];

  return availableShippingMethods?.length
    ? (availableShippingMethods.filter(
        (method) => method?.usDomestic === isDomestic,
      ) as CollectShippingMethod[])
    : [];
};

export const shippingAddress2Alternate = (
  shippingAddress?: CollectShippingAddress | null,
): CollectAlternatingShippingAddress => ({
  addressLine1: shippingAddress?.street1,
  addressLine2: shippingAddress?.street2,
  city: shippingAddress?.city,
  country: String(shippingAddress?.countryId),
  name: shippingAddress?.name,
  state: shippingAddress?.state,
  zip: shippingAddress?.zip,
});
