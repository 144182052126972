import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const WireTransferV2 = ({
  color = colors["neutral-1000"],
  ...rest
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...rest}
  >
    <path
      d="M8.33333 6.85893C7.66737 6.2442 6.49455 5.81661 5.41667 5.78984M5.41667 5.78984C4.13419 5.75798 2.98611 6.29353 2.98611 7.75638C2.98611 10.4487 8.33333 9.10253 8.33333 11.7948C8.33333 13.3304 6.91017 13.9901 5.41667 13.9406M5.41667 5.78984V4.16663M5.41667 13.9406C4.29158 13.9033 3.12658 13.4634 2.5 12.6923M5.41667 13.9406V15.8333M10.8333 9.99996H17.5M17.5 9.99996L14.3 6.66663M17.5 9.99996L14.3 13.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
