import { useSplitTreatments } from "@splitsoftware/splitio-react";

import {
  FeatureFlags,
  FeatureValue,
  TreatmentParsersMap,
} from "@/utils/featureFlags";
import { ESplitError, onSplitError } from "@/utils/split";

type UseFeatureReturn<TFeatureName extends FeatureFlags> = {
  isReady?: boolean;
  values?: Record<TFeatureName, FeatureValue<TFeatureName>>;
  errors?: Record<TFeatureName, Error | null | unknown>;
  isTimedout?: boolean;
};

export const useFeature = <const TFeatureName extends FeatureFlags>(
  featureNames: TFeatureName[],
): UseFeatureReturn<TFeatureName> => {
  const { isReady, treatments, isTimedout } = useSplitTreatments({
    names: featureNames,
    updateOnSdkUpdate: true,
  });

  if (isReady) {
    const values: Partial<Record<TFeatureName, FeatureValue<TFeatureName>>> =
      {};
    const errors: Partial<Record<TFeatureName, Error | null | unknown>> = {};

    featureNames.forEach((featureName) => {
      if (treatments[featureName].treatment === "control") {
        errors[featureName] = onSplitError(ESplitError.Control);
        return;
      }

      try {
        values[featureName] = TreatmentParsersMap[featureName](
          treatments[featureName],
        ) as FeatureValue<TFeatureName>;
      } catch (error) {
        errors[featureName] = onSplitError(ESplitError.Schema, error);
      }
    });

    return {
      isReady,
      isTimedout,
      values: Object.keys(values).length
        ? (values as Record<TFeatureName, FeatureValue<TFeatureName>>)
        : undefined,
      errors: Object.keys(errors).length
        ? (errors as Record<TFeatureName, Error | null | unknown>)
        : undefined,
    };
  }

  return { isReady: false, isTimedout };
};
