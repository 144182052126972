import { gql } from "./artifacts";

export const collectCheckoutV2CompletePayment = gql(`
    mutation webCheckoutV2CompletePaymentMutation(
      $cart:CollectCheckoutV2CompletePaymentCartInput
    ) {
      collectCheckoutV2CompletePayment(
        cart: $cart
      ) {
        successful
        messages {
          field
          message
          code
          template
          options {
            key
            value
          }
        }
        result {
          results {
            status
            paymentTransactionId
            amount{
              __typename
              ...Money
            }
          }
          invoiceUuids
        }
      }
    }
  `);
