import { gql } from "./artifacts";

//   "variables": {
//     "id": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//   }

export const collectInvoice = gql(`
  query webInvoiceQuery($collectInvoiceId: UUID!) {
    collectInvoice(id: $collectInvoiceId) {
      __typename
      buyersPremium {
        __typename
        amountInCents
        currency
      }
      integerId
    }
  }
`);
