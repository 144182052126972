import { gql } from "./artifacts";

//   "variables": {
//     "input": {
//       "fundsAmount": 0,
//       "invoiceId": "8836dd68-542e-11ef-a785-0a593f674edd",
//       "paymentIntentId": "pi_3PktYZDzX32i8C1U0q27zqL0",
//       "paymentMethod": [
//         "CARD"
//       ],
//       "shippingType": "VAULT"
//     }
//   }

export const collectCheckoutInvoiceConfirm = gql(`
  mutation webCheckoutInvoiceConfirmMutation($input: CollectCheckoutInvoiceConfirmInput!) {
    collectCheckoutInvoiceConfirm(input: $input) {
      __typename
      successful
      messages {
        __typename
        ...ErrorMessage
      }
      result {
        __typename
        invoice {
          __typename
          ...InvoiceDetail
        }
      }
    }
  }

  fragment BasicInvoice on CollectInvoice {
    __typename
    id
    type
    integerId
    status
    paidDate
    total {
      __typename
      ...SharedMoney
    }
    dueDate
  }

  fragment CollectInvoicePayment on CollectPayment {
    __typename
    paymentMethod
    amount {
      __typename
      ...SharedMoney
    }
    id
    date
  }


  fragment InvoiceDetail on CollectInvoice {
    __typename
    ...BasicInvoice
    paymentAttempts {
      __typename
      cardType
      last4
      status
    }
    paymentFees {
      __typename
      ...SharedMoney
    }
    type
    allowedPaymentMethods
    tax {
      __typename
      ...SharedMoney
    }
    subTotal {
      __typename
      ...SharedMoney
    }
    amountPaid {
      __typename
      ...SharedMoney
    }
    buyersPremium {
      __typename
      ...SharedMoney
    }
    amountPaidOutstanding {
      __typename
      ...SharedMoney
    }
    shipping {
      __typename
      ...SharedMoney
    }
    paymentTermDays
    invoiceDate
    payments {
      __typename
      ...CollectInvoicePayment
    }
    pendingPayments {
      __typename
      ...CollectInvoicePayment
    }
    lineItems {
      __typename
      listing {
        __typename
        ...ListedProduct
      }
    }
    shippingAddress {
      __typename
      name
      street1
      street2
      city
      state
      zip
      countryCode
      countryId
      phone
      vaultAccountId
    }
  }
  fragment ListedProduct on CollectListing {
    __typename
    id
    ...SharedListedProduct
    description
    variant {
      __typename
      ...ListedProductVariant
    }
    ...ListedProductOffer
    collectSales {
      __typename
      soldDate
      soldFor {
        __typename
        ...SharedMoney
      }
    }
    offerLimits {
      __typename
      min {
        __typename
        ...SharedMoney
      }
      max {
        __typename
        ...SharedMoney
      }
    }
  }
  fragment ListedProductOffer on CollectListing {
    __typename
    id
    myOffer {
      __typename
      ...ListingOfferHistory
    }
  }

  fragment ListedProductVariant on CollectVariant {
    __typename
    product {
      __typename
      ...MinimalGeneralProduct
    }
  }

  fragment ListingOfferBid on CollectFixedPriceListingOfferBid {
    __typename
    integerId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
  }

  fragment ListingOfferHistory on CollectFixedPriceListingOfferWithHistory {
    __typename
    id
    listingId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
    sellerViewedAt
    bidderViewedAt
    bids {
      __typename
      ...ListingOfferBid
    }
    listing {
      __typename
      listingStatus: status
    }
    offerRecord: previousOffers {
      __typename
      ...PreviousOfferHistory
    }
    paymentDetails {
      __typename
      invoiceId
      paymentDueDate
    }
  }

  fragment MinimalGeneralProduct on CollectProduct {
    __typename
    id
    images
    title
  }

  fragment PreviousOfferHistory on CollectFixedPriceListingOffer {
    __typename
    id
    listingId
    status
    amount {
      __typename
      ...SharedMoney
    }
    insertedAt
    expiresAt
    bids {
      __typename
      ...ListingOfferBid
    }
    listing {
      __typename
      listingStatus: status
    }
  }

  fragment SharedCollectAuction on CollectAuction {
    __typename
    id
    endsAt
    startsAt
    integerId
    auctionIntegerId
    name
    shortName
    payoutDate
    status
    collectListingTimer {
      __typename
      secondsUntilAuctionStart
      secondsUntilAuctionEnds
      secondsUntilExtednedBiddingEnds
      auctionEndsAt
      auctionStartsAt
      windowEndsAt
      asOf
      windowOrdinal
      windowDurationSeconds
    }
  }

  fragment SharedListedProduct on CollectListing {
    __typename
    id
    title
    integerId
    listingType
    currentBid {
      __typename
      ...SharedMoney
    }
    images
    subtitle
    marketplaceEyeAppeal
    startingPrice {
      __typename
      ...SharedMoney
    }
    listingStatus: status
    lotString
    bidCount
    highestBidder
    buyNowPrice {
      __typename
      ...SharedMoney
    }
    previousAskingPrice {
      __typename
      ...SharedMoney
    }
    isOwner
    allowOffers
    auction {
      __typename
      ...SharedCollectAuction
    }
    favoritedCount
    states {
      __typename
      isClosed
      isGreatPrice
      isWatching
      userBidStatus
      lastWindowWhenBidWasReceived
      userCanBuy
      userMaxBid {
        __typename
        ...SharedMoney
      }
    }
    collectSales {
      __typename
      soldDate
      soldFor {
        __typename
        ...SharedMoney
      }
    }
  }

  fragment SharedMoney on Money {
    __typename
    amountInCents
    currency
  }
`);
