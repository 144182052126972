import { gql } from "./artifacts";

export const collectCheckoutV2PaymentSummary = gql(`
    query webCheckoutV2PaymentSummaryQuery($cart: CollectCheckoutV2PaymentSummaryCartInput) {
        collectCheckoutV2PaymentSummary(cart: $cart) {
            order {
              date
              id
              status
            }
            totals {
                subTotal {
                    __typename
                    ...Money
                }
                shipping {
                    __typename
                    ...Money
                }
                tax {
                    __typename
                    ...Money
                }
                buyersPremium {
                    __typename
                    ...Money
                }
                creditCardFeeRate
                creditCardFee {
                    __typename
                    ...Money
                }
                total {
                    __typename
                    ...Money
                }
                amountPaid {
                    __typename
                    ...Money
                }
                amountPaidOutstanding {
                    __typename
                    ...Money
                }
            }
            payments {
                __typename
                ...CollectCheckoutV2Payment
            }
            pendingPayments {
                __typename
                ...CollectCheckoutV2Payment
            }
            fanaticsCollectSection {
                seller {
                    __typename
                    ...CollectSeller
                }
                invoiceType
                shipping {
                    type
                    address {
                        name
                        street1
                        street2
                        city
                        state
                        zip
                        country {
                            __typename
                            ...CollectCheckoutV2Country
                        }
                        phone
                    }
                }
                lineItems {
                    __typename
                    ...LineItem
                }
                totals {
                    total {
                        __typename
                        ...Money
                    }
                    subTotal {
                        __typename
                        ...Money
                    }
                    shipping {
                        __typename
                        ...Money
                    }
                    tax {
                        __typename
                        ...Money
                    }
                }
            }
            fanaticsAuthenticSection {
                seller {
                    __typename
                    ...CollectSeller
                }
                shipping {
                    type
                    address {
                        name
                        street1
                        street2
                        city
                        state
                        zip
                        country {
                            __typename
                            ...CollectCheckoutV2Country
                        }
                        phone
                    }
                }
                lineItems {
                    __typename
                    ...LineItem
                }
                invoiceType
                totals {
                    total {
                        __typename
                        ...Money
                    }
                    subTotal {
                        __typename
                        ...Money
                    }
                    shipping {
                        __typename
                        ...Money
                    }
                    tax {
                        __typename
                        ...Money
                    }
                }
            }
            burbankSportscardsSection {
                seller {
                    __typename
                    ...CollectSeller
                }
                shipping {
                    type
                    address {
                        name
                        street1
                        street2
                        city
                        state
                        zip
                        country {
                            __typename
                            ...CollectCheckoutV2Country
                        }
                        phone
                    }
                    method {
                        id
                        name
                        usDomestic
                        cost {
                            __typename
                            ...Money
                        }
                    }
                }
                lineItems {
                    __typename
                    ...LineItem
                }
                invoiceType
                totals {
                    total {
                        __typename
                        ...Money
                    }
                    subTotal {
                        __typename
                        ...Money
                    }
                    shipping {
                        __typename
                        ...Money
                    }
                    tax {
                        __typename
                        ...Money
                    }
                }
            }
            wireInfo {
                ...WireInfo
            }
        }
    }
`);
