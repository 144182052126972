import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import { EColor, ETextVariant } from "@fanatics-live/common-components";
import { t } from "i18next";

import {
  CollectAddress,
  CollectAlternatingShippingAddress,
  CollectShippingMethod,
  CollectShippingType,
  Money,
} from "@/gql";
import styles from "@/legacy/styles";
import { toDollars } from "@/utils";

import { AddressText } from "./AddressText";

export const ShippingOption = ({
  alternateShippingAddress,
  collectDefaultAddress,
  collectDefaultAddressError,
  shipping,
  shippingMethods,
  shippingType,
  tax,
  alternateAddressError,
}: {
  alternateShippingAddress?: CollectAlternatingShippingAddress;
  collectDefaultAddress?: CollectAddress | null;
  collectDefaultAddressError?: boolean;
  shippingType: CollectShippingType;
  shipping?: Money;
  shippingMethods: CollectShippingMethod[];
  tax?: Money;
  alternateAddressError?: boolean;
}) => {
  const hasShippingMethods = !!shippingMethods.length;

  const shippingChargesText = !!shipping?.amountInCents && (
    <Text as="span" fontSize="12px" fontWeight="700">
      <Badge
        {...styles.badge}
        background={EColor.BrandDark}
        color={EColor.White}
        textTransform="capitalize"
        mb="3px"
        ml="12px"
      >
        {hasShippingMethods
          ? t("cart.shipping.primary.from_cost", {
              cost: Math.min(
                ...shippingMethods.map(({ cost }) => toDollars(cost)),
              ),
            })
          : t("cart.shipping.primary.cost", { cost: toDollars(shipping) })}
      </Badge>
      {!!tax?.amountInCents && (
        <Text as="span" ml="4px">
          {t("cart.shipping.primary.tax")}
        </Text>
      )}
    </Text>
  );

  const shippingTimeText = !hasShippingMethods && (
    <Text {...styles.small} fontSize="10px" lineHeight="1" mt="3px" mb="14px">
      {t("cart.shipping.primary.time")}
    </Text>
  );

  return (
    <>
      {shippingType === CollectShippingType.Default &&
        !!collectDefaultAddress && (
          <Flex>
            <Box>
              <Text {...styles.h3} w="100%">
                {collectDefaultAddress.name}
                {shippingChargesText}
              </Text>
              {shippingTimeText}
              <AddressText
                {...{
                  ...collectDefaultAddress,
                  ...(hasShippingMethods && { name: undefined }),
                  phone: undefined,
                }}
                textProps={styles.p}
              />
              {collectDefaultAddressError && (
                <Text
                  variant={ETextVariant.Paragraph4}
                  fontWeight="400"
                  textTransform="uppercase"
                  color={EColor.BrandDanger}
                >
                  {t("cart.shipping.default.invalid_message")}
                </Text>
              )}
            </Box>
          </Flex>
        )}

      {shippingType === CollectShippingType.New && (
        <Flex>
          <Box>
            <Text {...styles.h3} w="100%">
              {t("cart.shipping.alternate.title")}
              {shippingChargesText}
            </Text>
            {shippingTimeText}
            <AddressText
              {...(alternateShippingAddress as CollectAddress)}
              textProps={styles.p}
            />
            {alternateAddressError && (
              <Text
                variant={ETextVariant.Paragraph4}
                fontWeight="400"
                textTransform="uppercase"
                color={EColor.BrandDanger}
              >
                {t("cart.shipping.default.invalid_message")}
              </Text>
            )}
          </Box>
        </Flex>
      )}

      {shippingType === CollectShippingType.Vault && (
        <Box>
          <Text {...styles.h3}>
            {t("cart.shipping.vault.title")}
            <Badge
              {...styles.badge}
              textTransform="capitalize"
              background={EColor.Lime}
              color={EColor.Black}
              ml="10px"
              mb="3px"
            >
              {t("cart.shipping.vault.free")}
            </Badge>
          </Text>
          <Text {...styles.p}>{t("cart.shipping.vault.secure")}</Text>
          <Text {...styles.p}>{t("cart.shipping.vault.tax")}</Text>
        </Box>
      )}
    </>
  );
};
