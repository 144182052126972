import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const ApplePayV2 = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill={colors["neutral-50"]}
    {...rest}
  >
    <rect width="36" height="24" rx="2" fill="#F7F7F7" />
    <path
      d="M16.7519 7.19861C18.309 7.19861 19.3933 8.27194 19.3933 9.83465C19.3933 11.4029 18.2867 12.4818 16.7129 12.4818H14.9888V15.2235H13.7432V7.1986L16.7519 7.19861ZM14.9888 11.4363H16.4181C17.5026 11.4363 18.1198 10.8524 18.1198 9.84022C18.1198 8.82814 17.5026 8.24974 16.4237 8.24974H14.9888V11.4363Z"
      fill="black"
    />
    <path
      d="M19.7164 13.5612C19.7164 12.5379 20.5005 11.9095 21.8909 11.8316L23.4925 11.7371V11.2867C23.4925 10.636 23.0531 10.2467 22.3191 10.2467C21.6238 10.2467 21.19 10.5803 21.0845 11.1031H19.95C20.0167 10.0464 20.9176 9.26787 22.3636 9.26787C23.7817 9.26787 24.6881 10.0186 24.6881 11.1921V15.224H23.5369V14.2619H23.5092C23.17 14.9126 22.4303 15.3241 21.6629 15.3241C20.5172 15.3241 19.7164 14.6123 19.7164 13.5612ZM23.4925 13.0329V12.5713L22.052 12.6603C21.3346 12.7104 20.9287 13.0273 20.9287 13.5279C20.9287 14.0394 21.3514 14.3731 21.9965 14.3731C22.8362 14.3731 23.4925 13.7948 23.4925 13.0329Z"
      fill="black"
    />
    <path
      d="M25.7783 17.3773V16.404C25.8671 16.4262 26.0673 16.4262 26.1674 16.4262C26.7235 16.4262 27.0239 16.1927 27.2073 15.5921C27.2073 15.5809 27.3131 15.2362 27.3131 15.2306L25.1999 9.37455H26.5011L27.9805 14.1351H28.0026L29.4821 9.37455H30.75L28.5587 15.5309C28.0584 16.9491 27.48 17.4051 26.2676 17.4051C26.1674 17.4051 25.8671 17.3939 25.7783 17.3773Z"
      fill="black"
    />
    <path
      d="M10.0671 7.97985C10.3673 7.60435 10.571 7.10017 10.5173 6.58496C10.0778 6.60681 9.54157 6.87489 9.23109 7.25068C8.95231 7.57249 8.70557 8.09778 8.7699 8.59139C9.26322 8.63418 9.75608 8.34481 10.0671 7.97985Z"
      fill="black"
    />
    <path
      d="M10.5138 8.68922C9.79738 8.64655 9.18825 9.09583 8.84612 9.09583C8.50381 9.09583 7.9799 8.71073 7.41325 8.72111C6.67572 8.73194 5.99138 9.14894 5.61712 9.81218C4.84732 11.139 5.41397 13.1071 6.16255 14.1877C6.52609 14.7223 6.9642 15.3109 7.54149 15.2898C8.08693 15.2684 8.30072 14.9366 8.96372 14.9366C9.62624 14.9366 9.81882 15.2898 10.3962 15.2791C10.995 15.2684 11.3693 14.7442 11.7328 14.2091C12.1499 13.5996 12.3206 13.0112 12.3313 12.9789C12.3206 12.9682 11.1767 12.5294 11.1661 11.2136C11.1553 10.1119 12.0642 9.58788 12.1069 9.55539C11.5937 8.79632 10.7918 8.71073 10.5138 8.68922Z"
      fill="black"
    />
  </svg>
);
