import { ReactElement } from "react";

import { IconProps } from "@/types/svgTypes";
import { colors } from "@/utils/colors";

export const AppleIconV2 = ({ ...rest }: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    {...rest}
  >
    <path
      d="M9.79744 2.83615C10.4078 2.07268 10.8221 1.04755 10.7128 0C9.81927 0.0444305 8.72888 0.589497 8.0976 1.35357C7.53077 2.00788 7.02909 3.07593 7.15988 4.07957C8.16292 4.16657 9.16503 3.5782 9.79744 2.83615Z"
      fill={colors["neutral-1000"]}
    />
    <path
      d="M10.7026 4.2773C9.24595 4.19053 8.00744 5.10402 7.31181 5.10402C6.6158 5.10402 5.55057 4.32102 4.39843 4.34212C2.89885 4.36415 1.50741 5.21202 0.746437 6.56054C-0.818755 9.25824 0.333384 13.2599 1.85545 15.457C2.5946 16.544 3.4854 17.7408 4.65917 17.6979C5.76818 17.6543 6.20287 16.9797 7.55092 16.9797C8.89799 16.9797 9.28955 17.6979 10.4635 17.6761C11.6809 17.6543 12.442 16.5886 13.1812 15.5005C14.0291 14.2614 14.3762 13.0649 14.3981 12.9993C14.3762 12.9775 12.0505 12.0853 12.029 9.41004C12.007 7.16999 13.8549 6.1045 13.9419 6.03842C12.8984 4.49505 11.2679 4.32102 10.7026 4.2773Z"
      fill={colors["neutral-1000"]}
    />
  </svg>
);
