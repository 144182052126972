import { ApolloError } from "@apollo/client";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js";
import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  CollectCheckoutDetails,
  CollectInvoice,
  CollectValidPaymentMethod,
} from "@/gql";
import { useCartContext, getInputOptions, useCheckoutDetails } from "@/hooks";
import {
  isBalanceCoveredByFunds,
  isSavedPaymentMethod,
  ETrackingEvents,
  track,
} from "@/utils";

import { useCompletePayment } from "./useCompletePayment";
import { useCreatePayment } from "./useCreatePayment";
import { useConfirmStripePaymentRequest } from "./useStripePaymentRequest";

export const useConfirmPayment = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const {
    confirmPaymentLoading,
    detailsOptions,
    paymentError,
    paymentMethod,
    savedPaymentMethodData,
    setConfirmPaymentLoading,
    setPaymentError,
    setPaymentMethod,
  } = useCartContext();
  const { details } = useCheckoutDetails(detailsOptions);
  const createPayment = useCreatePayment();
  const completePayment = useCompletePayment();
  const confirmStripePaymentRequest = useConfirmStripePaymentRequest();
  const startTimeRef = useRef(0);

  const confirmPayment = useCallback(
    async (paymentRequest?: PaymentRequest) => {
      const trackingData = {
        invoiceCategory: details?.invoiceCategory,
        invoiceType: details?.type,
        listingIds: [detailsOptions.listingId],
        paymentType: paymentMethod ? [paymentMethod] : undefined,
        total: details?.total.amountInCents,
        totalInDollars: details
          ? details?.total.amountInCents / 100
          : undefined,
        listing: details as CollectCheckoutDetails,
        listingOptions: detailsOptions,
      };

      try {
        setConfirmPaymentLoading(true);
        setPaymentError(undefined);
        startTimeRef.current = Date.now();

        if (!stripe) {
          throw new Error('"stripe" is not provided');
        }
        if (!details) {
          throw new Error('"listing" is not provided');
        }
        if (!paymentMethod) {
          throw new Error('"paymentMethod" is not provided');
        }
        if (isSavedPaymentMethod(paymentMethod) && !savedPaymentMethodData) {
          throw new Error('"savedPaymentMethodData" is not provided');
        }

        const fundsAmount = details.accountFundsToUse.amountInCents;
        const fundsCoverBalance = isBalanceCoveredByFunds(
          paymentMethod,
          details.accountFundsToUse,
          details.potentialTotalWithCreditCardFee,
          details.amountPaidOutstanding || details.total,
        );

        const paymentMethods = [
          ...(fundsAmount ? [CollectValidPaymentMethod.Funds] : []),
          ...(fundsCoverBalance ? [] : [paymentMethod]),
        ];

        const lockOptions = {
          ...getInputOptions(detailsOptions, details),
          paymentMethod: paymentMethods,
        };

        const paymentLock = await createPayment(
          detailsOptions.invoiceId
            ? { invoice: lockOptions }
            : { listing: lockOptions },
        );
        const { clientSecret, invoiceId, paymentIntentId } = paymentLock || {};

        if (clientSecret) {
          if (
            paymentRequest &&
            [
              CollectValidPaymentMethod.ApplePay,
              CollectValidPaymentMethod.GooglePay,
            ].includes(paymentMethod)
          ) {
            // Confirm the Apple/Google payment request inside the Stripe
            // PaymentRequest handler.
            const { canceled, error } = await confirmStripePaymentRequest(
              clientSecret,
              paymentMethods,
              paymentRequest,
            );

            if (error) {
              throw error;
            }

            if (canceled) {
              setPaymentMethod(undefined, details);
              setConfirmPaymentLoading(false);
              return;
            }
          } else {
            // Confirm card/ACH payment methods with Stripe. Not used for Wire
            // or when account funds cover the balance.
            const { error: confirmPaymentError } =
              paymentMethod === CollectValidPaymentMethod.Ach
                ? await stripe.confirmUsBankAccountPayment(clientSecret, {
                    payment_method: savedPaymentMethodData?.id,
                  })
                : await stripe.confirmCardPayment(clientSecret, {
                    payment_method: savedPaymentMethodData?.id,
                  });

            if (confirmPaymentError) {
              throw new Error(confirmPaymentError.message);
            }
          }
        }

        const result = await completePayment({
          ...getInputOptions({ ...detailsOptions, invoiceId }, details),
          fundsAmount,
          paymentIntentId,
          paymentMethod: paymentMethods,
        });

        const { integerId } = result?.invoice ?? {};

        track(ETrackingEvents.PAY_CHECKOUT_TAPPED, {
          ...trackingData,
          executionTimeInSeconds: (Date.now() - startTimeRef.current) / 1000,
          invoice: result?.invoice as CollectInvoice,
          invoiceId,
          success: true,
        });

        navigate(`/invoices/${integerId}/details`);
      } catch (error) {
        track(ETrackingEvents.PAY_CHECKOUT_TAPPED, {
          ...trackingData,
          error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
          executionTimeInSeconds: (Date.now() - startTimeRef.current) / 1000,
          isNetworkError:
            error instanceof ApolloError ? !!error.networkError : undefined,
          success: false,
        });

        setPaymentError(error as Error);
      } finally {
        setConfirmPaymentLoading(false);
      }
    },
    [
      completePayment,
      confirmStripePaymentRequest,
      createPayment,
      details,
      detailsOptions,
      navigate,
      paymentMethod,
      savedPaymentMethodData,
      setConfirmPaymentLoading,
      setPaymentError,
      setPaymentMethod,
      stripe,
    ],
  );

  return {
    confirmPayment,
    error: paymentError,
    loading: confirmPaymentLoading,
  };
};
