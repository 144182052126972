import { datadogLogs } from "@datadog/browser-logs";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { objectToSnake } from "ts-case-convert";

import { SEGMENT_WRITE_KEY } from "@/env";
import {
  CollectCheckoutDetails,
  CollectCheckoutV2PaymentMethod,
  CollectCheckoutV2Details,
  CollectCurrentUser,
  CollectInvoice,
  CollectInvoiceCategory,
  CollectInvoiceType,
  CollectListing,
  CollectValidPaymentMethod,
  CollectPaymentType,
  WebCheckoutV2PaymentSummaryQueryQuery,
} from "@/gql";
import { ICheckoutDetailsOptions } from "@/hooks";
import { EInvoiceCategory, TNewInvoice } from "@/legacy/invoices/types";

export enum ETrackingEvents {
  CONFIRMATION_CHECKOUT_SCREEN_VIEWED = "Confirmation Checkout Screen Viewed",
  NEW_ACH_CHECKOUT_TAPPED = "New ACH Checkout Tapped",
  NEW_CREDIT_CARD_CHECKOUT_TAPPED = "New Credit Card Checkout Tapped",
  ORDER_SUMMARY_CHECKOUT_TAPPED = "Order Summary Checkout Tapped",
  PAY_CHECKOUT_TAPPED = "Pay Checkout Tapped",
  PAYMENT_METHOD_SELECTED = "Payment Method Selected",
  SAVED_ACH_CHECKOUT = "Saved ACH Checkout",
  SAVED_CREDIT_CARD_CHECKOUT = "Saved Credit Card Checkout",
  SELECT_ACH_CHECKOUT = "Select ACH Checkout",
  SELECT_APPLE_PAY_CHECKOUT = "Select Apple Pay Checkout",
  SELECT_CREDIT_CARD_CHECKOUT = "Select Credit Card Checkout",
  SELECT_GOOGLE_PAY_CHECKOUT = "Select Google Pay Checkout",
  SELECT_WIRE_CHECKOUT = "Select Wire Checkout",
  VIEW_CHECKOUT = "View Checkout",

  // CHECKOUT V2

  PAY_CHECKOUT_TAPPED_V2 = "Pay Checkout Tapped V2",
  VIEW_CART_CHECKOUT = "View Cart Checkout",
  CONFIRMATION_CHECKOUT_V2_SCREEN_VIEWED = "Confirmation Checkout V2 Screen Viewed",
  MULTICART_SUMMARY_SCREEN_VIEWED = "Multicart Summary Screen Viewed",
}

export interface IEventAttributes {
  [ETrackingEvents.CONFIRMATION_CHECKOUT_SCREEN_VIEWED]: {
    paymentMethod: CollectValidPaymentMethod[];
    invoice: TNewInvoice;
    invoiceCategory: EInvoiceCategory;
  };
  [ETrackingEvents.NEW_ACH_CHECKOUT_TAPPED]: Record<string, never>;
  [ETrackingEvents.NEW_CREDIT_CARD_CHECKOUT_TAPPED]: Record<string, never>;
  [ETrackingEvents.ORDER_SUMMARY_CHECKOUT_TAPPED]: Record<string, never>;
  [ETrackingEvents.PAY_CHECKOUT_TAPPED]: {
    brand?: string; // Needs definition
    invoiceId?: string;
    invoiceCategory?: CollectInvoiceCategory | null;
    invoiceType?: CollectInvoiceType;
    listingIds?: CollectListing["id"][];
    paymentType?: CollectValidPaymentMethod[];
    success: boolean;
    error?: string;
    total?: number;
    totalInDollars?: number;
    isNetworkError?: boolean;
    executionTimeInSeconds?: number;
    invoice?: CollectInvoice;
    listing?: CollectCheckoutDetails;
    listingOptions?: ICheckoutDetailsOptions;
  };
  [ETrackingEvents.PAYMENT_METHOD_SELECTED]: {
    invoiceId?: string;
    cartId?: string;
    invoiceCategory?: CollectInvoiceCategory | null;
    invoiceType?: CollectInvoiceType;
    listingIds?: CollectListing["id"][];
    paymentType?: CollectValidPaymentMethod | CollectCheckoutV2PaymentMethod;
  };
  [ETrackingEvents.SAVED_ACH_CHECKOUT]?: { status: string }; // Needs definition
  [ETrackingEvents.SAVED_CREDIT_CARD_CHECKOUT]?: { status: string }; // Needs definition
  [ETrackingEvents.SELECT_ACH_CHECKOUT]: Record<string, never>;
  [ETrackingEvents.SELECT_APPLE_PAY_CHECKOUT]: Record<string, never>;
  [ETrackingEvents.SELECT_CREDIT_CARD_CHECKOUT]: Record<string, never>;
  [ETrackingEvents.SELECT_GOOGLE_PAY_CHECKOUT]: Record<string, never>;
  [ETrackingEvents.SELECT_WIRE_CHECKOUT]: Record<string, never>;
  [ETrackingEvents.VIEW_CHECKOUT]: {
    error?: string;
    invoiceCategory?: CollectInvoiceCategory | null;
    globalErrorMessage?: string;
    graphql: boolean;
    invoice?: CollectInvoice;
    listing?: CollectCheckoutDetails;
    listingOptions?: ICheckoutDetailsOptions;
  };
  [ETrackingEvents.PAY_CHECKOUT_TAPPED_V2]: {
    listingIds?: (number | undefined)[];
    paymentType?: CollectCheckoutV2PaymentMethod;
    total?: number;
    totalInDollars?: number;
    listing?: CollectCheckoutV2Details;
    success?: boolean;
    invoiceUuids?: string[];
    executionTimeInSeconds?: number;
    error?: string;
    isNetworkError?: boolean;
  };
  [ETrackingEvents.VIEW_CART_CHECKOUT]: {
    error?: string;
    globalErrorMessage?: string;
    graphql: boolean;
    cartOptions?: CollectCheckoutV2Details;
  };
  [ETrackingEvents.CONFIRMATION_CHECKOUT_V2_SCREEN_VIEWED]: {
    paymentMethod: CollectPaymentType[];
    invoice: CollectInvoice;
  };
  [ETrackingEvents.MULTICART_SUMMARY_SCREEN_VIEWED]: {
    burbankSection: WebCheckoutV2PaymentSummaryQueryQuery["collectCheckoutV2PaymentSummary"]["burbankSportscardsSection"];
    collectSection: WebCheckoutV2PaymentSummaryQueryQuery["collectCheckoutV2PaymentSummary"]["fanaticsCollectSection"];
    fanaticsAuthenticSection: WebCheckoutV2PaymentSummaryQueryQuery["collectCheckoutV2PaymentSummary"]["fanaticsAuthenticSection"];
  };
}

const analytics = SEGMENT_WRITE_KEY
  ? AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY })
  : undefined;

export const identify = (
  id: CollectCurrentUser["tenantId"],
  traits: { email: string },
) => {
  datadogLogs.logger.log(id, traits);
  analytics?.identify(id, traits);
};

export const track = <E extends ETrackingEvents>(
  event: E,
  traits?: IEventAttributes[E],
) => {
  datadogLogs.logger.log(event, traits);
  analytics?.track(event, objectToSnake(traits ?? {}));
};
