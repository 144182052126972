import {
  ErrorPage,
  IErrorPageProps,
  getMonolithUrls,
} from "@fanatics-live/common-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MONOLITH_URL } from "@/env";

interface ICheckoutError extends IErrorPageProps {
  message?: string;
  statusCode?: number;
}

export const CheckoutError = ({
  message,
  statusCode,
  ...restProps
}: ICheckoutError) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { key: keyLocation } = useLocation();
  const { listingId } = useParams();

  const handleClick = () => {
    if (keyLocation === "default") {
      if (listingId) {
        window.location.href = `${getMonolithUrls(MONOLITH_URL).fixedPriceUrl}/${listingId}`;
      } else {
        navigate("/invoices", { replace: true });
      }
    } else {
      navigate(-1);
    }
  };
  return (
    <ErrorPage
      fanaticsCollectBaseUrl={MONOLITH_URL}
      buttonText={t("btn.error_page_button")}
      onButtonClick={handleClick}
      message={message}
      statusCode={statusCode}
      showEvents={false}
      {...restProps}
    />
  );
};
