import { z } from "zod";

export enum ESplitError {
  Control,
  Schema,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onSplitError = (errorType: ESplitError, error?: any) => {
  switch (errorType) {
    case ESplitError.Control: {
      // The control treatment is a reserved treatment in Split
      // https://help.split.io/hc/en-us/articles/360020528072-Control-treatment
      const controlErrorText =
        "Control found in split parseTreatment, something went wrong.";
      const controlError = new Error(controlErrorText);

      return error || controlError;
    }
    case ESplitError.Schema: {
      const schemaErrorText = "Error parsing split treatment config.";

      return new Error(schemaErrorText);
    }
    default: {
      // NOTE: This should never happen.
      const unknownErrorText = "unknown split error";
      const unknownError = new Error(unknownErrorText);

      return unknownError;
    }
  }
};

export const booleanParser = (
  treatmentWithConfig: SplitIO.TreatmentWithConfig,
) => {
  const schema = z.union([z.literal("on"), z.literal("off")]);

  return schema.parse(treatmentWithConfig.treatment) === "on";
};

export const numberParser = (
  treatmentWithConfig: SplitIO.TreatmentWithConfig,
) => {
  const schema = z.coerce.number();

  return schema.parse(treatmentWithConfig.treatment);
};

export const createStringParser = <
  T extends readonly [z.ZodTypeAny, ...z.ZodTypeAny[]],
>(
  schema: z.ZodUnion<T>,
) => {
  return (treatmentWithConfig: SplitIO.TreatmentWithConfig) =>
    schema.parse(treatmentWithConfig.treatment);
};
