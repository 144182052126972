import { gql } from "./artifacts";

//   "variables": {
//     "input": {
//       "listing": {
//         "listingId": "f21d66fc-2054-11ee-84f2-0a58a9feac02",
//         "listingType": "FIXED_PRICE",
//         "shippingType": "VAULT"
//       }
//     }

export const collectCheckoutDetails = gql(`
  query webCheckoutDetailsQuery($input: CollectCheckoutDetailsInput!) {
    collectCheckoutDetails(input: $input) {
      __typename
      allowedPaymentMethods
      amountPaid {
        __typename
        ...SharedMoney
      }
      amountPaidOutstanding {
        __typename
        ...SharedMoney
      }
      accountFundsToUse {
        __typename
        ...SharedMoney
      }
      availableShippingMethods {
        cost {
          __typename
          ...SharedMoney
        }
        id
        name
        usDomestic
      }
      availableShippingTypes
      balanceDue {
        __typename
        ...SharedMoney
      }
      businessCategory
      creditCardFee {
        __typename
        ...SharedMoney
      }
      creditCardFeePct
      dueDate
      invoiceCategory
      invoiceDate
      lineItems {
        __typename
        amount {
          __typename
          ...SharedMoney
        }
        description
        listing {
          __typename
          currentBid {
            __typename
            amountInCents
          }
          images
          subtitle
          title
        }
        title
      }
      payments {
        __typename
        paymentMethod
        amount {
          __typename
          ...SharedMoney
        }
      }
      pendingPayments {
        __typename
        paymentMethod
      }
      potentialBalanceDueWithCreditCardFee {
        __typename
        ...SharedMoney
      }
      potentialCreditCardFee {
        __typename
        ...SharedMoney
      }
      potentialTotalWithCreditCardFee {
        __typename
        ...SharedMoney
      }
      shipping {
        __typename
        ...SharedMoney
      }
      shippingAddress {
        __typename
        city
        countryCode
        countryId
        name
        phone
        state
        street1
        street2
        vaultAccountId
        zip
      }
      shippingMethod {
        __typename
        cost {
          __typename
          ...SharedMoney
        }
        id
        name
      }
      shippingType
      status
      subTotal {
        __typename
        ...SharedMoney
      }
      tax {
        __typename
        ...SharedMoney
      }
      total {
        __typename
        ...SharedMoney
      }
      type
      errors {
        code
        field
        message
      }
    }
  }

  fragment SharedMoney on Money {
    __typename
    amountInCents
    currency
  }
`);
