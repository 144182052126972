import { PropsWithChildren, useEffect, useState } from "react";

import { useFeature } from "@/hooks/useFeature";
import { FeatureFlags } from "@/utils/featureFlags";

import { initialize } from "./initialize";

export const DatadogProvider = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const { values, isReady } = useFeature([
    FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE,
    FeatureFlags.DATADOG_RUM_SESSION_SAMPLE_RATE,
    FeatureFlags.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE,
  ]);

  const {
    [FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE]:
      logsSessionSampleRate,
    [FeatureFlags.DATADOG_RUM_SESSION_SAMPLE_RATE]: sessionSampleRate,
    [FeatureFlags.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE]:
      sessionReplaySampleRate,
  } = values ?? {};

  useEffect(() => {
    if (isReady && !isInitialized) {
      initialize(
        logsSessionSampleRate || 0,
        sessionSampleRate || 0,
        sessionReplaySampleRate || 0,
      );

      setIsInitialized(true);
    }
  }, [
    isInitialized,
    isReady,
    logsSessionSampleRate,
    sessionReplaySampleRate,
    sessionSampleRate,
  ]);

  return children;
};
