import { TFunction } from "i18next";

import { CollectPaymentType } from "@/gql";

export const getPaymentMethodText = (
  paymentMethod: CollectPaymentType,
  t: TFunction,
) => {
  const paymentMethodTypeMap: Record<CollectPaymentType, string> = {
    [CollectPaymentType.ApplePay]: t("order_details.apple_pay.title"),
    [CollectPaymentType.GooglePay]: t("order_details.google_pay.title"),
    [CollectPaymentType.Wire]: t("order_details.wire.title"),
    [CollectPaymentType.Funds]: t("order_details.account_funds.title"),
    [CollectPaymentType.Card]: t("order_details.credit_card.title"),
    [CollectPaymentType.Ach]: t("order_details.ach.title"),
    [CollectPaymentType.Other]: t("order_details.other.title"),
    [CollectPaymentType.Crypto]: t("order_details.crypto.title"),
    [CollectPaymentType.Check]: t("order_details.check.title"),
  };

  return paymentMethodTypeMap[paymentMethod];
};
