import { booleanParser, numberParser } from "./split";

export enum FeatureFlags {
  WEB_ENABLE_OFFER_NOTIFICATION_BADGES = "Web_Enable_Offer_Badges",
  WEB_ENABLE_NEW_CHECKOUT_DETAILS_PAGE = "Web_Enable_Checkout_New_Details_Page",
  WEB_ENABLE_PLAID_PA_APPROVAL = "Web_Enable_Plaid_PA_Approval",
  DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE = "Datadog_Browser_Logs_Session_Sample_Rate",
  DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE = "Datadog_RUM_Session_Replay_Sample_Rate",
  DATADOG_RUM_SESSION_SAMPLE_RATE = "Datadog_RUM_Session_Sample_Rate",
}

export const TreatmentParsersMap = {
  [FeatureFlags.WEB_ENABLE_OFFER_NOTIFICATION_BADGES]: booleanParser,
  [FeatureFlags.WEB_ENABLE_NEW_CHECKOUT_DETAILS_PAGE]: booleanParser,
  [FeatureFlags.WEB_ENABLE_PLAID_PA_APPROVAL]: booleanParser,
  [FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE]: numberParser,
  [FeatureFlags.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE]: numberParser,
  [FeatureFlags.DATADOG_RUM_SESSION_SAMPLE_RATE]: numberParser,
} as const satisfies Record<FeatureFlags, unknown>;

export type FeatureValue<TFeatureName extends FeatureFlags> = ReturnType<
  (typeof TreatmentParsersMap)[TFeatureName]
>;
