import { gql } from "./artifacts";

export const collectCheckoutV2LockForPayment = gql(`
    mutation webCheckoutV2LockForPaymentMutation(
      $cart: CollectCheckoutV2LockForPaymentCartInput!
    ) {
      collectCheckoutV2LockForPayment(
        cart: $cart
      ) {
        successful
        messages {
          field
          message
          code
          template
          options {
            key
            value
          }
        }
        result {
          clientSecret
          paymentIntentId
          invoiceUuids
          cartUuid
        }
      }
    }
  `);
